<template>
	<div class="slideMenu">

    <div id="loop-container" class="Loop js-loop">
        <div class="item">
          <span class="link" @click="goToRoute('our-purpose')"><span>OUR</span> PURPOSE</span>
        </div>
        <div class="item">
          <span class="link" @click="goToRoute('work')">WORK</span>
        </div>
        <div class="item">
          <span class="link" @click="goToRoute('contact')">CONTACT</span>
        </div>
        <div class="item">
          <span class="link" @click="goToRoute('our-purpose')"><span>OUR</span> PURPOSE</span>
        </div>
        <div class="item">
          <span class="link" @click="goToRoute('work')">WORK</span>
        </div>
        <div class="item">
          <span class="link" @click="goToRoute('contact')">CONTACT</span>
        </div>

        <!--
        These blocks are the same as the first blocks to get that looping illusion going.
        You need to add clones to fill out a full viewport height.
        -->
        <div class="item is-clone">
          <span class="link" @click="goToRoute('our-purpose')"><span>OUR</span> PURPOSE</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('work')">WORK</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('contact')">CONTACT</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('our-purpose')"><span>OUR</span> PURPOSE</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('work')">WORK</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('contact')">CONTACT</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('our-purpose')"><span>OUR</span> PURPOSE</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('work')">WORK</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('contact')">CONTACT</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('our-purpose')"><span>OUR</span> PURPOSE</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('work')">WORK</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('contact')">CONTACT</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('our-purpose')"><span>OUR</span> PURPOSE</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('work')">WORK</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('contact')">CONTACT</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('our-purpose')"><span>OUR</span> PURPOSE</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('work')">WORK</span>
        </div>
        <div class="item is-clone">
          <span class="link" @click="goToRoute('contact')">CONTACT</span>
        </div>
    </div>


	</div>
</template>

<script>

export default {
  name: 'SliderMenuNew',
  data() {
    return {
    }
  },
  methods: {
    goToRoute: function(route) {
      // animate all elements off the menu, close the menu slider and go to selected page
      [].map.call(document.querySelectorAll('.menu-slider'), function(el) {
              el.classList.remove('active');
      });
      [].map.call(document.querySelectorAll('body'), function(el) {
            el.classList.remove('menu-active');
      });
      this.$router.push('/' + route);
    },
    // scrolling: function() {
    //   console.log("scrolling");
    // }
  },
  mounted() {

    // var timer = null;
    // document.addEventListener('scroll', (event) => {

    //     if(timer !== null) {
    //         clearTimeout(timer);    

    //         if (event.target.id === 'loop-container') { 
              
    //           $('.Loop').addClass('scrolling');

    //         }

    //     }
    //     timer = setTimeout(() => {

    //       // if (event.target.id === 'loop-container') { 
    //       //   this.scrolling();
    //       // }
    //       $('.Loop').removeClass('scrolling');

    //     }, 150);

    // }, true /*Capture event*/);


    //   $('.loop').on( 'scroll', function(){
    //      this.scrolling();
    //   });


      var doc = window.document,
        context = doc.querySelector('.js-loop'),
        clones = context.querySelectorAll('.is-clone'),
        disableScroll = false,
        scrollHeight = 0,
        scrollPos = 0,
        clonesHeight = 0,
        i = 0;

      function getScrollPos () {
        return (context.pageYOffset || context.scrollTop) - (context.clientTop || 0);
      }

      function setScrollPos (pos) {
        context.scrollTop = pos;
      }

      function getClonesHeight () {
        clonesHeight = 0;

        for (i = 0; i < clones.length; i += 1) {
          clonesHeight = clonesHeight + clones[i].offsetHeight;
        }

        return clonesHeight;
      }

      function reCalc () {
        scrollPos = getScrollPos();
        scrollHeight = context.scrollHeight;
        clonesHeight = getClonesHeight();

        if (scrollPos <= 0) {
          setScrollPos(1); // Scroll 1 pixel to allow upwards scrolling
        }
      }

      function scrollUpdate () {
        if (!disableScroll) {
          scrollPos = getScrollPos();

          if (clonesHeight + scrollPos >= scrollHeight) {
            // Scroll to the top when you’ve reached the bottom
            setScrollPos(1); // Scroll down 1 pixel to allow upwards scrolling
            disableScroll = true;
          } else if (scrollPos <= 0) {
            // Scroll to the bottom when you reach the top
            setScrollPos(scrollHeight - clonesHeight);
            disableScroll = true;
          }
        }

        if (disableScroll) {
          // Disable scroll-jumping for a short time to avoid flickering
          window.setTimeout(function () {
            disableScroll = false;
          }, 40);
        }
      }

      function init () {
        reCalc();
        
        context.addEventListener('scroll', function () {
          window.requestAnimationFrame(scrollUpdate);
        }, false);

        window.addEventListener('resize', function () {
          window.requestAnimationFrame(reCalc);
        }, false);
      }

      if (document.readyState !== 'loading') {
        init()
      } else {
        doc.addEventListener('DOMContentLoaded', init, false)
      }

      // Just for this demo: Center the middle block on page load
      // window.onload = function () {
      //   setScrollPos(Math.round(clones[0].getBoundingClientRect().top + getScrollPos() - (context.offsetHeight - clones[0].offsetHeight) / 2));
      // };
  }
}
</script>

<style lang="scss" scoped>
.slideMenu {
  height: 100%;
  width: 100%;

    .Loop {
      position: relative;
      height: 100%;
      overflow: auto;
      overflow: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    .item {
      position: relative;
      text-align: center;
      min-height: 100px;
      /*max-height: 700px;*/
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media(max-width: 880px) {
        min-height: auto;
        height: 100px;
        .link {
          font-size: 67px;
          letter-spacing: 10px;
        }
      }

      @media(max-width: 680px) {
        min-height: auto;
        height: 70px;
        .link {
          font-size: 47px;
          letter-spacing: 2px;
        }
      }

    }

    ::scrollbar {
      display: none;
    }

    /*.red {
      background: #FF4136;
    }
    .green {
      background: #2ECC40;
    }
    .blue {
      background: #0074D9;
    }
    .orange {
      background: rebeccapurple;
    }*/

    h1 {
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      font-size: 80px;
      letter-spacing: 5px;
      color: #fff;
      text-transform: uppercase;
    }
}


  .link {
    cursor: pointer;
    color: transparent;
    transition: 0.3s;
    font-size: 110px;
    text-transform: uppercase;
    font-family: AkzidenzGrotesk-BoldExtended;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
    letter-spacing: 15px;
    position: relative;
    /*height: 100%;*/
    white-space: nowrap;

/*    @media(max-width:1000px) {
      font-size: 90px;
    }
    @media(max-width:850px) {
      font-size: 80px;
    }
    @media(max-width:680px) {
      font-size: 80px;

    }*/
  }
  .link:hover {
    color: #ffffff;
  }

  .Loop.scrolling {

    .link {
      font-size: 70px;
    }
  }

  .link {
    @media(max-width:680px) {
      span {
        display: none;
      }
    }
  }


</style>
