<template>
    <nav id="nav">

        <div class="topbar">
          <div class="anchor logo mobile-only" @click="goToRoute('')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.79 94.66"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M7.32,24.49V38.17h4c2.35,0,3.63-1.08,3.63-4.39V29.89c0-4.13-1.34-5.41-4.52-5.41H7.32Zm0-18.13V18.13h2.73c2.61,0,4.21-1.14,4.21-4.71V10.93c0-3.18-1.09-4.57-3.56-4.57Zm13.93,3.89v1.59c0,4.57-1.4,7.44-4.52,8.9v.12C20.48,22.33,22,25.63,22,30.34V34c0,6.87-3.63,10.56-10.63,10.56H.32V0H10.88C18.13,0,21.25,3.37,21.25,10.25Z"/><polygon points="26.15 0 33.14 0 33.14 38.17 44.66 38.17 44.66 44.53 26.15 44.53 26.15 0"/><path d="M54.71,6.36V38.17h3.94c2.23,0,3.56-1.14,3.56-4.33V10.69c0-3.19-1.33-4.33-3.56-4.33ZM47.72,0H58.79c7,0,10.43,3.89,10.43,11V33.53c0,7.13-3.43,11-10.43,11H47.72Z"/><path d="M7,49.56V83.91c0,3.19,1.4,4.33,3.63,4.33s3.62-1.14,3.62-4.33V49.56h6.62V83.47c0,7.13-3.55,11.19-10.43,11.19S0,90.6,0,83.47V49.56Z"/><polygon points="23.6 49.56 45.23 49.56 45.23 55.92 37.91 55.92 37.91 94.1 30.92 94.1 30.92 55.93 23.6 55.93 23.6 49.56"/><path d="M55.28,55.93V87.74h3.95c2.22,0,3.56-1.14,3.56-4.33V60.25c0-3.18-1.34-4.32-3.56-4.32Zm-7-6.37H59.35c7,0,10.44,3.88,10.44,11V83.08c0,7.13-3.43,11-10.44,11H48.28Z"/></g></g></svg>
          </div>
          <!-- <span class="tagline mobile-only">Ideas that <br/>unite you like blood.</span> -->
          <!-- <span class="tagline animated fadeInRight animation-delay-600 desktop-only">Ideas that unite <br/>you like blood</span> -->
          <div class="tagline animated fadeInRight animation-delay-600 desktop-only">
            <img src="../assets/images/icons/tagline.svg" />

            <!-- <div class="tagline-type">Ideas that unite <br/> you like blood</div> -->
          </div>
          <div class="menu mobile-only" @click="toggleMenuSlider()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 30">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <line class="cls-1" y1="2" x2="55" y2="2"/>
                  <line class="cls-1" y1="15" x2="55" y2="15"/>
                  <line class="cls-1" y1="28" x2="55" y2="28"/>
                </g>
              </g>
            </svg>
          </div>
        </div>

        <div class="desktop-sidebar">
          <div class="logo animated fadeInLeft animation-delay-300" @click="goToRoute('')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.79 94.66"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M7.32,24.49V38.17h4c2.35,0,3.63-1.08,3.63-4.39V29.89c0-4.13-1.34-5.41-4.52-5.41H7.32Zm0-18.13V18.13h2.73c2.61,0,4.21-1.14,4.21-4.71V10.93c0-3.18-1.09-4.57-3.56-4.57Zm13.93,3.89v1.59c0,4.57-1.4,7.44-4.52,8.9v.12C20.48,22.33,22,25.63,22,30.34V34c0,6.87-3.63,10.56-10.63,10.56H.32V0H10.88C18.13,0,21.25,3.37,21.25,10.25Z"/><polygon points="26.15 0 33.14 0 33.14 38.17 44.66 38.17 44.66 44.53 26.15 44.53 26.15 0"/><path d="M54.71,6.36V38.17h3.94c2.23,0,3.56-1.14,3.56-4.33V10.69c0-3.19-1.33-4.33-3.56-4.33ZM47.72,0H58.79c7,0,10.43,3.89,10.43,11V33.53c0,7.13-3.43,11-10.43,11H47.72Z"/><path d="M7,49.56V83.91c0,3.19,1.4,4.33,3.63,4.33s3.62-1.14,3.62-4.33V49.56h6.62V83.47c0,7.13-3.55,11.19-10.43,11.19S0,90.6,0,83.47V49.56Z"/><polygon points="23.6 49.56 45.23 49.56 45.23 55.92 37.91 55.92 37.91 94.1 30.92 94.1 30.92 55.93 23.6 55.93 23.6 49.56"/><path d="M55.28,55.93V87.74h3.95c2.22,0,3.56-1.14,3.56-4.33V60.25c0-3.18-1.34-4.32-3.56-4.32Zm-7-6.37H59.35c7,0,10.44,3.88,10.44,11V83.08c0,7.13-3.43,11-10.44,11H48.28Z"/></g></g></svg>
          </div>
          <div class="menu animated fadeInLeft animation-delay-400" @click="toggleMenuSlider()">
            <svg class="mobile-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 30">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <line class="cls-1" y1="2" x2="55" y2="2"/>
                  <line class="cls-1" y1="15" x2="55" y2="15"/>
                  <line class="cls-1" y1="28" x2="55" y2="28"/>
                </g>
              </g>
            </svg>
          </div>
          <div class="circle animated fadeInLeft animation-delay-500">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M17.5,0a16.93,16.93,0,0,1,3.41.34A17.18,17.18,0,0,1,27.22,3a17.72,17.72,0,0,1,4.83,4.83,17.18,17.18,0,0,1,2.61,6.31,17.27,17.27,0,0,1,0,6.82,17.18,17.18,0,0,1-2.61,6.31,17.72,17.72,0,0,1-4.83,4.83,17.18,17.18,0,0,1-6.31,2.61,17.27,17.27,0,0,1-6.82,0,17.18,17.18,0,0,1-6.31-2.61A17.72,17.72,0,0,1,3,27.22,17.18,17.18,0,0,1,.34,20.91a17.27,17.27,0,0,1,0-6.82A17.18,17.18,0,0,1,3,7.78,17.72,17.72,0,0,1,7.78,3,17.18,17.18,0,0,1,14.09.34,16.93,16.93,0,0,1,17.5,0Z"/></g></g></svg>
          </div>
        </div>

        <div class="menu-slider makescroll">
          <div id="close-icon" class="close-icon" @click="toggleMenuSlider()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><line class="cls-1" x1="3" y1="2" x2="46" y2="45"/><line class="cls-1" x1="2" y1="46" x2="45" y2="3"/></g></g></svg>
          </div>
          <div class="menu-slider-content">

            <!-- <SliderMenu/> -->
            <SliderMenuNew/>

          </div>
        </div>

    </nav>
</template>

<script>
import SliderMenuNew from './SliderMenuNew.vue'
export default {
  name: 'Navigation',
  components: {
    SliderMenuNew
  },
  data() {
    return {
      
    }
  },
  methods: {
    toggleMenuSlider: function() {
        [].map.call(document.querySelectorAll('.menu-slider'), function(el) {
              el.classList.toggle('active');
        });
        [].map.call(document.querySelectorAll('body'), function(el) {
              el.classList.toggle('menu-active');
        });
    },
    goToRoute: function(route) {
      // animate all elements off the menu, close the menu slider and go to selected page
      [].map.call(document.querySelectorAll('.menu-slider'), function(el) {
              el.classList.remove('active');
      });
      [].map.call(document.querySelectorAll('body'), function(el) {
            el.classList.remove('menu-active');
      });

      this.$router.push('/' + route);
    }
  },
  mounted() {

    $(window).scroll(function() {
        if($(window).scrollTop() > 40) {
          $('.logo').addClass('shrink');
          $('.menu').addClass('shrink');
        } else {
          $('.logo').removeClass('shrink');
          $('.menu').removeClass('shrink');
        }
    });

  }
}
</script>


<style lang="scss" scoped>
#nav {
  padding: 0px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .tagline-type {
    font-family: AkzidenzGroteskBQ-MedExt, Akzidenz-Grotesk BQ;
    font-size: 1.6vw;
    text-align: right;
    float: right;

    @media(max-width: 1200px) {
      font-size: 20px;
    }

    @media(max-width: 680px) {
      display: none;
    }
  }

  .topbar {
    position: absolute;
    right: 100px;
    left: 100px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media(max-width: 680px) {
      position: fixed;
      left: 0px;
      justify-content: space-between;
      z-index: 999;
      background-color: #ffffff;
      right: 0px;
      top: 0px;
      padding: 20px 0px 10px 0px;
      transition: 0.3s;
    }

    .logo {
      margin-left: 30px;
      cursor: pointer;
      svg {
        transition: 0.3s;
        width: 45px;
      }
      &.shrink svg{
        @media(max-width: 680px) {
          width: 25px;
        }
      }
    }

    .menu {
      margin-right: 30px;
      cursor: pointer;
      svg {
        transition: 0.3s;
        width: 30px;
      }
      &.shrink svg{
        @media(max-width: 680px) {
          width: 20px;
        }
      }
      .cls-1 {
        fill: none;
        stroke: #707070;
        stroke-width: 4px;
        transition: 0.3s;
      }
    }

    .tagline {
      text-align: right;
      margin-right: 0px;
      font-weight: 500;
      font-size: 1.6em;
      font-family: AkzidenzGrotesk-MediumExtended;
      width: 17%;
      min-width: 200px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      img {
        width: 100%;
      }

      @media(max-width: 680px) {
        display: none;
      }
    }
  }

  .desktop-sidebar {
    width: 6%;
    min-width: 80px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    /*background-color: #ffffff;*/
    z-index: 99999;

    @media(max-width: 680px) {
      display: none;
    }

    .logo {
      cursor: pointer;
      width: 45%;
      svg {
         /*width: 45px;*/
         width: 100%;
         transition: 0.5s;
      }
    }

    .menu {
      width: 30%;
      cursor: pointer;
      svg {
        /*width: 36px;*/
        width: 100%;
      }
      .cls-1 {
        fill:none;
        stroke:#707070;
        stroke-width:4px;
        transition: 0.5s;
      }
    }

    .circle {
      width: 17%;
      svg {
        /*width: 18px;*/
        width: 100%;
        transition: 0.5s;
      }
    }
  }

  .close-icon {
    .cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4px;}
  }
}


  .menu.mobile-only {
      .cls-1 {
        stroke:#000000 !important;
      }
    }


.menu-slider {
    width: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: -100%;
    background-color: rgba(0,0,0,0.9);
    overflow-x: hidden;
    box-sizing: border-box;
    transition: 0.5s;
    /*padding: 0px 40px;*/
    overflow: hidden;
    overflow: scroll;
}

.menu-slider.active {
    width: 100%;
    left: 0;
}

.menu-slider-content {
    position: relative;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    opacity: 0;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    /*margin-left: -300px;*/
    /*margin-top: 140px;*/

    .menu-link {
      color: #707070;
      cursor: pointer;
    }

}

.menu-slider .close-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 14px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 999999;

    svg {
      width: 20px;
    }
}



</style>
