import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { bodyClass: 'home' },
    },
    {
      path: '/work',
      redirect: '/'
    },
    {
      path: '/contact',
      name: 'contact',
      meta: { bodyClass: 'contact' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue')
    },
    {
      path: '/our-purpose',
      name: 'our-purpose',
      meta: { bodyClass: 'philosophy' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "philosophy" */ './views/Philosophy.vue')
    },
    {
      path: '/netball-victoria',
      name: 'netball-victoria',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "netball-victoria" */ './views/projects/NetballVictoria.vue')
    },
    {
      path: '/western-bulldogs',
      name: 'western-bulldogs',
      meta: { bodyClass: 'work-page fullwidth' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "western-bulldogs" */ './views/projects/WesternBulldogs.vue')
    },
    {
      path: '/nike-matildas',
      name: 'nike-matildas',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "nike-matildas" */ './views/projects/NikeMatildas.vue')
    },
    {
      path: '/ca-watch-me',
      name: 'ca-watch-me',
      meta: { bodyClass: 'work-page fullwidth' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "ca-watch-me" */ './views/projects/CAWatchMe.vue')
    },
    {
      path: '/nike-mercurial',
      name: 'nike-mercurial',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "nike-mercurial" */ './views/projects/NikeMercurial.vue')
    },
    {
      path: '/nike-city-fc',
      name: 'nike-city-fc',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "nike-city-fc" */ './views/projects/NikeCityFC.vue')
    },
    {
      path: '/nike-play-gold',
      name: 'nike-play-gold',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "nike-play-gold" */ './views/projects/NikePlayGold')
    },
    {
      path: '/super-netball',
      name: 'super-netball',
      meta: { bodyClass: 'work-page fullwidth' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "super-netball" */ './views/projects/SuperNetball.vue')
    },
    {
      path: '/nike-all-whites',
      name: 'nike-all-whites',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "nike-all-whites" */ './views/projects/NikeAllWhites.vue')
    },
    {
      path: '/right-here-right-now',
      name: 'right-here-right-now',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "right-here-right-now" */ './views/projects/RightHereRightNow.vue')
    },
    {
      path: '/wsw',
      name: 'wsw',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "wsw" */ './views/projects/NikeWSW.vue')
    },
    {
      path: '/nike-women',
      name: 'nike-women',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "nike-women" */ './views/projects/NikeWomen.vue')
    },
    {
      path: '/ca-watch-me-play',
      name: 'ca-watch-me-play',
      meta: { bodyClass: 'work-page fullwidth' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "ca-watch-me-play" */ './views/projects/CAWatchMePlay.vue')
    },
    {
      path: '/fitzroy-lions',
      name: 'fitzroy-lions',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "ca-watch-me-play" */ './views/projects/FitzroyLions.vue')
    },
    {
      path: '/ca-watch-me-2',
      name: 'ca-watch-me-2',
      meta: { bodyClass: 'work-page' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "ca-watch-me-2" */ './views/projects/CAWatchMe2.vue')
    },
    {
      path: '/zerafa',
      name: 'zerafa',
      meta: { bodyClass: 'work-page fullwidth' },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "zerafa" */ './views/projects/Zerafa.vue')
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
