<template>
  <div class="home">

    <div class="desktop">

      <section class="row boxed">
        <div class="col" style="width: 39%;">
          <router-link to="/fitzroy-lions" class="project-link">
            <img src="/static/images/home/fitzroy-lions.jpg" alt="" title="" />
            <div class="caption">
              <p>Fitzroy Lions FC</p>
              <p>BLD UTD Sponsorship</p>
            </div>
          </router-link>
        </div>
        <div class="col" style="width: 51%;">
          <router-link to="/western-bulldogs" class="project-link">
            <img src="/static/images/home/L2.jpg" alt="" title="" />
            <div class="caption">
              <p>Western Bulldogs</p>
              <p>Belong To The West</p>
            </div>
          </router-link>
        </div>
      </section>

      <section class="full-width">
        <router-link to="/zerafa" class="project-link">
            <img src="/static/images/home/zerafa.gif" alt="" title="" />
            <div class="caption">
              <p>Michael Zerafa</p>
              <p>Faith</p>
            </div>
        </router-link>
      </section>

      <section class="full-width copy" style="background: transparent;">
        <div class="">
          <div class="copy-container standout-copy">
            We build deep emotional connections, make culturally relevant work and create new fans for your brand.
          </div>
        </div>
      </section>

      <section class="row boxed" style="justify-content: center;">
        <div class="col" style="width: 35%; margin-right: 12%; margin-top: 20%">
          <router-link to="/netball-victoria" class="project-link">
            <img src="/static/images/home/L1.jpg" alt="" title="" />
            <div class="caption">
              <p>Netball Victoria</p>
              <p>Vixens 4 Life</p>
            </div>
          </router-link> 
        </div>
        <div class="col horizontal" style="width: 35%; margin-top: -20%;">
          <router-link to="/ca-watch-me-2" class="project-link">
            <img src="/static/images/home/L-WM2.jpg" alt="" title="" />
            <div class="caption">
              <p>Cricket Australia</p>
              <p>WBBL|05</p>
            </div>
          </router-link>
        </div>
      </section>

      <section class="full-width">
        <router-link to="/nike-matildas" class="project-link">
            <img src="/static/images/home/L3.jpg" alt="" title="" />
            <div class="caption">
              <p>Nike</p>
              <p>Matildas World Cup Campaign</p>
            </div>
        </router-link>
      </section>

      <section class="row boxed" style="justify-content: center;">
        <div class="col" style="width: 35%; margin-right: 12%; margin-top: -20%">
          <router-link to="/ca-watch-me" class="project-link">
            <img src="/static/images/home/L4.jpg" alt="" title="" />
            <div class="caption">
              <p>Cricket Australia</p>
              <p>#WatchMe</p>
            </div>
          </router-link>
        </div>
        <div class="col horizontal" style="width: 35%; margin-top: 20%;">
          <router-link to="/nike-mercurial" class="project-link">
            <img src="/static/images/home/L5_GIF.gif" alt="" title="" />
            <div class="caption">
              <p>Nike</p>
              <p>Mercurial</p>
            </div>
          </router-link>
        </div>
      </section>

      <section class="full-width">
        <router-link to="/nike-play-gold" class="project-link">
            <img src="/static/images/home/L-NikeGold.jpg" alt="" title="" />
            <div class="caption">
              <p>Nike</p>
              <p>Play Gold</p>
            </div>
        </router-link>
      </section>

      <section class="row boxed" style="justify-content: center;">
        <div class="col" style="width: 35%; margin-right: 12%; margin-top: -20%">
          <router-link to="/nike-all-whites" class="project-link">
            <img src="/static/images/home/L9.jpg" alt="" title="" />
            <div class="caption">
              <p>Nike</p>
              <p>All White Kit Launch</p>
            </div>
          </router-link>
        </div>
        <div class="col horizontal" style="width: 35%; margin-top: 20%;">
          <router-link to="/nike-city-fc" class="project-link" >
            <img src="/static/images/home/L10.jpg" alt="" title="" />
            <div class="caption">
              <p>Nike</p>
              <p>City FC Kit Launch</p>
            </div>
          </router-link>
        </div>
      </section>

      <section class="full-width">
        <router-link to="/super-netball" class="project-link">
            <img src="/static/images/home/super_netball.gif" alt="" title="" />
            <div class="caption">
              <p>Super Netball</p>
              <p>All On The Line</p>
            </div>
        </router-link>
      </section>

      <section class="row boxed flip-order">
        <div class="col" style="width: 55%; margin-right: 8%; margin-top: 35%; position: relative;">
          <router-link to="/nike-women" class="project-link">
            <img src="/static/images/home/L-Nike-women.jpg" alt="" title="" />
            <div class="caption">
              <p>Nike</p>
              <p>Women</p>
            </div>
          </router-link>
        </div>
        <div class="col horizontal" style="width: 35%; margin-top: -25%;;">
          <router-link to="/wsw" class="project-link" >
            <img src="/static/images/home/L12.jpg" alt="" title="" />
            <div class="caption">
              <p>Nike</p>
              <p>WSWFC Kit Launch</p>
            </div>
          </router-link>
        </div>
      </section>

      <div class="spacer-md"></div>
      <div class="spacer-md"></div>

      <div class="home-footer">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; 2019 by Blood UTD</span>
      </div>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'home',
  methods: {
    playNikeWomen: function() {
      $('.nike-women-video').get(0).play();
      $('.nike-women-play').hide();
    },
    playSuperNetball: function() {
      $('.super-netball-video').get(0).play();
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.home {

  .home-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: AkzidenzGrotesk-MediumExtended;
    padding: 20px 0px 20px 0px;
    margin-left: -50px;

    @media(max-width: 680px) {
      padding: 20px 0px 40px 0px;
      margin-left: 0px;
    }

    a {
      font-size: 1.2em;
      margin-bottom: 5px;
    }
    span {
      font-size: 0.8em;
    }
  }

  .play-btn {
    position: absolute;
    top: 37%;
    left: 44%;
    
    img {
      max-width: 40px;
    }
  }

  section {
    padding: 50px 0px;
    @media(max-width: 680px) {
      padding: 0px 0px;
    }
  }

  .project-link {
    text-decoration: none;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 680px) {
      flex-direction: column;
    }

    &.boxed {
      padding: 50px 100px 50px 50px;
      @media(max-width: 680px) {
        padding: 0px;
      }
    }

    .col {

      @media(max-width: 680px) {
        width: 100% !important;
        margin: 0px !important; 
        margin-bottom: 40px !important;
      }

      img {
        @media(max-width: 680px) {
          width: 100% !important;
        }
      }
    }

    &.flip-order {
      @media(max-width: 680px) {
        .col:first-child {
          order: 2;
        }
      }
    }
  }
  .caption {

    p {
      margin: 5px 0px;
      font-family: AkzidenzGrotesk-Light, Helvetica, Arial, sans-serif;
    }
  }

  .full-width {
    margin-left: -7%;
    @media(max-width: 680px) {
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: 40px !important;
    }

    .caption {
      margin-left: 6%;
    }
  }

  .copy {
    padding: 20px 20px;
    margin-bottom: 60px;
    margin-top: 40px;
    font-family: AkzidenzGrotesk-Light, Helvetica, Arial, sans-serif;
    @media(max-width: 680px) {
      margin-bottom: 40px;
      margin-top: 0px;
    }
  }

  .copy-container {
    /*max-width: 1400px;*/
    margin: 0 auto;
    color: #ffffff;
    font-size: 2.6em;
    text-align: center;
    
    @media(max-width: 680px) {
      max-width: none;
      /*font-size: 1.8em;*/
    }
  }

  .standout-copy {
      width: 85%;
      font-size: 3vw;
      color: #ffffff;
      font-family: AkzidenzGrotesk-MediumExtended;
      -webkit-text-stroke-width: 1.6px;
      -webkit-text-stroke-color: #000000;
      letter-spacing: 2px;
      @media(max-width: 680px) {
        width: 100%;
        /*font-size: 26px;*/
        color: #000000;
        -webkit-text-stroke: unset;
        text-align: center;
      }
  }

}
</style>
