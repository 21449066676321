<template>
  <div id="app" v-cloak>
    <Navigation/>
    <div class="page-container">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'

export default {
  components: {
    Navigation
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    initCursor: function() {

    },
  },
  mounted() {
    this.loading = false;
  }
}
</script>

<style lang="scss">


/***********************************************
  Fonts and globals
***********************************************/

@font-face {
  font-family: AkzidenzGrotesk-Bold;
  src: url(/static/fonts/AkzidenzGrotesk-Bold.otf);
}
@font-face {
  font-family: AkzidenzGrotesk-BoldExtended;
  src: url(/static/fonts/AkzidenzGrotesk-BoldExtended.otf);
}
@font-face {
  font-family: AkzidenzGrotesk-Medium;
  src: url(/static/fonts/AkzidenzGrotesk-Medium.otf);
}
@font-face {
  font-family: AkzidenzGrotesk-MediumExtended;
  src: url(/static/fonts/AkzidenzGrotesk-MediumExtended.otf);
}
@font-face {
  font-family: AkzidenzGrotesk-Regular;
  src: url(/static/fonts/AkzidenzGrotesk-Regular.otf);
}
@font-face {
  font-family: AkzidenzGrotesk-Light;
  src: url(/static/fonts/AkzidenzGrotesk-Light.otf);
}
::-webkit-scrollbar { 
    display: none; 
}

.spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999999;
  transition: 0.3s;
}

v-cloak { display: none }

.wow { visibility: hidden; } 

body, html {
  margin: 0px;
  height: 100%;
  transition: 0.3s;
}

body {
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 10px;
}

body.menu-active {
  overflow: hidden;
}

#app {
  font-family: AkzidenzGrotesk-Regular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  /*height: 100%;*/
  position: relative;
}
a {
  color: #000000;
}
.clear {
  clear: both;
}
img {
  width: 100%;
}
/*Fade transition for views*/
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}

.spacer-sm {
  height: 20px;
}

.spacer-md {
  height: 40px;
  @media(max-width: 680px) {
    height: 20px;
  }
}

.spacer-lg {
  height: 80px;
  @media(max-width: 680px) {
    height: 40px;
  }
}

/***********************************************
  Page layout for contact and philosophy pages
***********************************************/

.contact {
  background-color: #000000;
  transition: 0.3s;

  .page-container {
    background: transparent;
  }

  #nav .topbar {
    .tagline.desktop-only {
      display: none;
    }
  }

  #nav .desktop-sidebar {
    background: transparent;
    .logo {
      svg {
        fill: #f9f9f9;
      }
    }
    .menu {
      .cls-1 {
        stroke:#f9f9f9;
      }
    }
    .circle {
      svg {
        fill: #f9f9f9;
      }
    }
  }

  @media(max-width: 680px) {
      transition: 0s;
      background-color: #ffffff;
  }

}


.philosophy {
  background-color: #000000;
  transition: 0.3s;
  
  .page-container {
    background: transparent;
  }

  #nav .topbar {
    .tagline.desktop-only {
      display: none;
    }

    .logo {
      svg {
          fill: #f9f9f9;
        }
      }
      .menu {
        .cls-1 {
          stroke: #f9f9f9;
          @media(max-width: 680px) {
            stroke: #ffffff !important;
          }
        }
      }
      .circle {
        svg {
          fill: #f9f9f9;
        }
      }
      @media(max-width: 680px) {
        background-color: #000000;

        .tagline {
          color: #f9f9f9;
        }
      }
  }

  #nav .desktop-sidebar {
    background: transparent;
    .logo {
      svg {
        fill: #f9f9f9;
      }
    }
    .menu {
      .cls-1 {
        stroke:#f9f9f9;
      }
    }
    .circle {
      svg {
        fill: #f9f9f9;
      }
    }
  }

  @media(max-width: 680px) {
  }

}

.menu-active {
  #nav .desktop-sidebar {
    background-color: transparent;
    transition: 0.3s;
    .logo {
      svg {
        fill: #f9f9f9;
      }
    }
    .menu {
      .cls-1 {
        stroke:#f9f9f9;
      }
    }
    .circle {
      svg {
        fill: #f9f9f9;
      }
    }
  }
}


.home .menu-slider-work-link {
  color: #ffffff !important;
}

.contact .menu-slider-contact-link {
  color: #ffffff !important;
}

.philosophy .menu-slider-philosophy-link {
  color: #ffffff !important;
}


/**********************
  Global styles
***********************/

.mobile-only {
  display: none;
}
.desktop-only {
  display: block;
}
@media(max-width: 680px) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}

.page-container {
  min-height: calc(100vh - 100px);
  position: absolute;
  top: 100px;
  left: 6%;
  right: 0px;
  bottom: 0px;
  transition: 0.3s;

  max-width: 1400px;
  margin: 0 auto;

/*  overflow: hidden;
  overflow: scroll;*/

    @media(max-width: 680px) {
      left: 30px;
      right: 30px;
    }
}

.home .page-container {
    max-width: none;
}

.home .container {
    max-width: 1400px;
    margin: 0 auto;
}

.work-page .page-container {

    max-width: 1000px;

    @media(min-width: 1600px) {
      max-width: 1200px;
    }

    @media(min-width: 1800px) {
      max-width: 1400px;
    }
}

.work-page .page-container  {
  @media(min-width: 680px) {
    top: 0px;
    right: 100px;
    left: 100px;
  }
  @media(min-width: 1000px) {
    right: 170px;
    left: 170px;
  }

}

.work-page #nav .topbar {
  .tagline.desktop-only {
    display: none;
  }
}

.section {
  padding: 40px 0px;
  position: relative;
  @media(max-width: 680px) {
     padding: 20px 0px;
  }
}

.home .footer {
  padding-right: 100px !important;
  @media(max-width: 680px) {
      padding: 0px 0px 20px 0px !important;
  }
}

@media screen and (max-width: 680px) {
    .wow {
        animation-name: none !important;
        visibility: visible !important;
    }
}


.flickity-viewport {
  width: 100%;
  overflow: visible;
}
.flickity-button:disabled {
  opacity: 0;
}


/*.flickity-prev-next-button.next {
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: transparent;
    width: 25% !important;
    transform: none;
    border-radius: 0px;
    height: 100%;
    cursor: url(/static/images/next-white.png), default !important;

    svg {
      display: none;
    }
}

.flickity-prev-next-button.previous {
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: transparent;
    width: 25% !important;
    transform: none;
    border-radius: 0px;
    height: 100%;
    cursor: url(/static/images/prev-white.png), default !important;

    svg {
      display: none;
    }
}*/


/** {
  cursor: none;
}

figure#mouse-pointer {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    margin-left: -8px; 
    display: block;
    z-index: 999999;
}

a {
  cursor: none;
  transition: 0.3s;
}*/


.menu-slider.active .menu-slider-content {
    opacity: 1;

    .slider-link {
      display: block;
    }
}

.section-two-img {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 49%;
  }
}

.slick-list {
  overflow: visible !important;
}
.slick-active:nth-child(1) {
  background: red !important;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 5;

  a {
    height: 150px;
    width: 45%;
    position: relative;
    display: flex;
    align-items: center;
  }

 /* .prev {
    cursor: url(/static/images/prev.png), default !important;
    justify-content: flex-start;
  }

  .next {
    cursor: url(/static/images/next.png), default !important;
    justify-content: flex-end;
  }*/

  @media(max-width: 680px) {
    .mobile {
      display: block;
    }
  }

}

.callout-copy {
  max-width: 100%;
  
  &.desktop-only {
    width: 100%;
    font-size: 34px;
    color: transparent;
    font-family: AkzidenzGrotesk-MediumExtended;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: #000000;
    letter-spacing: 2px;

    h1 {
      color: transparent;
    }

    @media(max-width: 680px) {
      display: none;
    }
  }

  &.mobile-only {
    max-width: 100%;
    h1 {
      font-family: AkzidenzGrotesk-MediumExtended;
      font-size: 24px;
      line-height: 28px;
      color: #908D8D;
    }

    @media(min-width: 680px) {
      display: none;
    }
  }
}





/***********************************************
  Project page header
***********************************************/

  .header {
    position: relative;

    .header-images {
      position: relative;


      img:last-child {
        width: 15%;
        position: absolute;
        right: 10px;
        bottom: -20px;
      }
    }

    .meta {
      .client {
        font-size: 0.9em;
        font-family: AkzidenzGrotesk-MediumExtended;
        margin-bottom: 5px;
        @media(max-width: 680px) {
          font-size: 0.7em;
          margin-bottom: 0px;
        }
      }
      .project {
        font-size: 0.9em;
        font-family: AkzidenzGrotesk-BoldExtended;
        border-bottom: 2px solid #000;
        @media(max-width: 680px) {
          font-size: 0.7em;
          margin-bottom: 5px;
        }
      }
    }
    .copy {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 20px;

      @media(max-width: 1000px) {
        flex-direction: column;
      }

      @media(min-width: 1400px) {
        justify-content: flex-start;
      }
      .col-1 {
        width: 49%;
        /*margin-right: 40px;*/

        @media(max-width: 1000px) {
          width: 100%;
        }
        h1 {
          font-size: 2.4em;
          font-family: AkzidenzGrotesk-MediumExtended;
          margin-bottom: 0px;
          margin-top: 0px;

          @media(max-width: 1000px) {
            margin-bottom: 10px;
          }

          @media(max-width: 680px) {
            font-size: 2em;
          }
        }
        @media(min-width: 1400px) {
          max-width: 500px;
          margin-right: 40px;
        }
      }

      .col-2 {
        width: 49%;
        @media(max-width: 1000px) {
          width: 100%;
        }
        p {
          font-family: AkzidenzGrotesk-Regular;
          font-size: 1.2em;
          line-height: 30px;
          margin: 0px;
          @media(max-width: 680px) {
            font-size: 1em;
            line-height: 23px;
          }
        }
        @media(min-width: 1400px) {
          max-width: 500px;
        }
      }
    }
  }

  .two-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .col {
      width: 50%;
    }
  }

  .video {
    outline: none;
  }

  .standout-copy {
      width: 70%;
      font-size: 40px;
      color: #ffffff;
      font-family: AkzidenzGrotesk-MediumExtended;
      -webkit-text-stroke-width: 1.4px;
      -webkit-text-stroke-color: #000000;
      letter-spacing: 2px;

      @media(max-width: 1000px) {
          width: 100%;
          font-size: 36px;
          -webkit-text-stroke-width: 1.2px;
      }

      @media(max-width: 680px) {
          width: 100%;
          font-size: 26px;
          color: #000000;
          -webkit-text-stroke: unset;
          text-align: center;
      }
  }

  .overlay-img {

      @media(max-width: 680px) {
          margin-top: 20px !important;
      }
  }

  .overlay-img-mobile {
    @media(max-width: 680px) {
      margin-top: -80px !important;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 150px 0px 40px;
    @media(max-width: 680px) {
      padding: 40px 0px;
    }

    .prev {
      cursor: pointer;
      @media(max-width: 680px) {
        /*display: none;*/
      }
      img {
        max-width: 70px;
        @media(max-width: 680px) {
          max-width: 40px;
        }
      }
    }
    .footer-contact {
      max-width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: AkzidenzGrotesk-MediumExtended;
      a {
        font-size: 1.2em;
        margin-bottom: 5px;
        @media(max-width: 680px) {
          display: none;
        }
      }
      span {
        font-size: 0.8em;
      }
    }
    .next {
      cursor: pointer;
      @media(max-width: 680px) {
        /*display: none;*/
      }
      img {
        max-width: 70px;
        @media(max-width: 680px) {
          max-width: 40px;
        }
      }
    }
  }

  video {
    cursor: pointer;
  }

  .mobile-full {
    @media(max-width: 680px) {
      width: 100% !important;
    }
  }

  .poster-image {
    background-size: cover;
    bottom: 0;
    left: 0;
    opacity: 1.0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in;
    z-index: 1;
    cursor: pointer;
  }

  .no-negative-top-mobile {
    @media(max-width: 680px) {
      margin-top: 40px !important;   
    }
  }

  .clear {
    clear: both;
  }

.mobile-mockup-wrapper {
  position: relative;
}

.mobile-mockup-container {
    position: relative;
    padding-top: 50%;
    padding-bottom: 120.5%;
    height: 0;
}
.mobile-mockup-container video {
    box-sizing: border-box;
    background: url(/static/images/mobile-video-placeholder.png) center center no-repeat;
    background-size: contain;
    padding: 10.9% 16% 10.8%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
    outline: none;
}

.fullwidth .page-container {
  /*width: calc(100% - 60px);*/
  max-width: none;
  left: auto;
  right: auto;
  position: relative;
  /*padding: 0px 20px;*/
}

.fullwidth .container-block {
    max-width: 1000px;
    margin: 0 auto;
    width: calc(100% - 60px);
    @media(min-width: 1600px) {
      max-width: 1200px;
    }
    @media(min-width: 1800px) {
      max-width: 1400px;
    }
    @media(min-width: 680px) {
      /*padding-right: 100px;
      padding-left: 100px;*/
      width: calc(100% - 200px);
      padding: 0px;
    }
}

.fullwidth .container-block  {
  @media(min-width: 680px) {
    top: 0px;
  }
  @media(min-width: 1000px) {
    right: 170px;
    left: 170px;
  }
}

.mobile-fullwidth {
  @media(max-width: 680px) {
    margin: 0px -40px 0px -40px;
    width: calc(100% + 80px);
  }
}
</style>
